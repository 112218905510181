*{
  font-family: 'Roboto', sans-serif;
  /*font-size: 14px;*/
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  /*--generalColor: rgb(86, 175, 213); !*use: nav bar,*!*/
  /*--textForGeneralColor: white; !*use: nav bar, nav bar color drop link, SearchInput, border drop link container, table Header *!*/
  /*--hoverForGeneralColor: #8fc9e2; !*use: nav bar, hover nav drop link*!*/
  /*--baseTextColor: #4d535b;  !*use: table Header *!*/
  /*--dropBackground: #acd3e3; !* use: backgraund drop link container *!*/
  /*--headerTextH: #FA6900; !* use: h3, hover Button *!*/
  /*--labevColor: #60B99A; !* use: количество загруженных элементов док., SearchInput, Button, table Header *!*/
  /*--tableHeader: #E0E4CC; !* use: table Header*!*/
  /*--tableBorder: #cccccd;*/

  /*--generalColor: #263876; !*use: nav bar,*!*/
  /*--textForGeneralColor: white; !*use: nav bar, nav bar color drop link, SearchInput, border drop link container, table Header *!*/
  /*--hoverForGeneralColor: #8fc9e2; !*use: nav bar, hover nav drop link*!*/
  /*--baseTextColor: #4d535b;  !*use: table Header *!*/
  /*--dropBackground: #acd3e3; !* use: backgraund drop link container *!*/
  /*--headerTextH: #263876; !* use: h3, hover Button *!*/
  /*--labevColor: #809AF2; !* use: количество загруженных элементов док., SearchInput, Button, table Header *!*/
  /*--tableHeader: #C9D0E7; !* use: table Header*!*/

  /*--generalColor: #1B5790; !*use: nav bar,*!*/
  /*--textForGeneralColor: #E5ECF3; !*use: nav bar, nav bar color drop link, SearchInput, border drop link container, table Header *!*/
  /*--hoverForGeneralColor: #CC2A41; !*use: nav bar, hover nav drop link*!*/
  /*--baseTextColor: #4d535b;  !*use: table Header *!*/
  /*--dropBackground: #64908A; !* use: backgraund drop link container *!*/
  /*--headerTextH: #CC2A41; !* use: h3, hover Button *!*/
  /*--labevColor: #90B557; !* use: количество загруженных элементов док., SearchInput, Button, table Header *!*/
  /*--tableHeader: #E1DFD2; !* use: table Header*!*/
  /*--tableBorder: #cccccd;*/

  /*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
  /*--generalColor: #426f99;*/
  /*--textForGeneralColor: #E5ECF3;*/
  /*--hoverForGeneralColor: #cc2941;*/
  /*--baseTextColor: #4d535b;*/
  /*--dropBackground: #4d6881;*/
  /*--headerTextH: #CC2A41;*/
  /*--labevColor: #426f99;*/
  /*--tableHeader: #c0c9d1;*/
  /*--tableBorder: #8ca7c0;*/

  /*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
  /*-------Nav Bar---------------------------------------------*/
  --navBarBackground: #426f99; /* use: header, menu */
  --textForNavBar: #E5ECF3; /* use: header, menu, link */
  --hoverNavBar: #cc2941; /* use: header, menu, link, exit button */
  --dropLinkBackground: #4d6881; /* use: drop link */
  /*------Buttons--------------------------------------------------*/
  --backgroundButton: #426f99;
  --colorTextButton: #fff;
  --hoverButton: #cc2941;
  /*------General Header Text--------------------------------------*/
  --headerTextH: #CC2A41;
  --labelBackground: #426f99;
  --labelColor: #fff;
  /*------Table-----------------------------------------------------*/
  --backgroundTableHeader: #c0c9d1;
  --colorTextTableHeader: #4d535b;
  --tableBorder: #8ca7c0;
  --backgroundActiveRow: #45975f36;
  --colorTextActiveRow: #456f97;
  /*-------Question Modal----------------------------------------*/
  --colorQuestionIcon: #CC2A41;
  /*-------Lister Icons----------------------------------------*/
  --colorListerIcon: #8ca7c0;
  /*-------Tabs----------------------------------------*/
  --colorTextTab: #4d535b;
  --activeBacgroundTab: linear-gradient(0deg,rgb(255, 255, 255),rgba(66, 111, 153, 0.4)),#fefefe;
  --activeColorTextTab: #426f99;
  --hoverBackgroundTab: linear-gradient(0deg,rgb(255, 255, 255),#45975f36),#fefefe;
  --hoverColorTextTab: #426f99;
  --borderTab: #ccc;
  /*-------Footer--------------------------------------------*/
  --backgroundFooter: #426f99;
  --colorTextFooter: #fff;
  /*-------Error arrow--------------------------------------------*/
  --arrowErrorColor: #CC2A41;
  --backgroundError: #edafb5;
  --colorError: #65131b;
  /*-------Warning------------------------------------------------*/
  --warningIcon: #ffa500;
  --warningText: #b35a09;
  --backgroundWarningButton: #ffa500;
  --colorTextWarningButton: #fff;
  --hoverWarningButton: #ee9814;
  /*------Dropdown items---------------------------------------------------*/
  --backgroundDropdownItems: #426f99;
  --colorTextDropdownItems: #fff;
  --hoverBackgroundDropdownItems: #CC2A41;
  /*------Block Filter-----------------------------------------------------*/
  --hoverBackgroundHoverFilter: #45975f36;
  --backgroundHoverFilter:#f2f2f2;
  /*------Block Print-----------------------------------------------------*/
  --backgroundPrintSettings: #E5ECF3;
  /*------Bright Button and Modal-----------------------------------------------------*/
  --backgroundBrightButton: #cc2941;
  --colorTextBrightButton: #fff;
  --hoverBrightButton: #426f99;
  /*------Modal Block-----------------------------------------------------*/
  --colorTitle: #426f99;
  --colorModalHeader: #cc2941;
}


.nav-tabs .nav-link.active {
  color: var(--activeColorTextTab) !important;
  background: var(--activeBacgroundTab)!important;
  border-color: var(--borderTab) var(--borderTab)#fff !important;
}
.nav-tabs .nav-link:hover {
  color: var(--hoverColorTextTab) !important;
  background: var(--hoverBackgroundTab) !important;
  border-color: var(--borderTab) var(--borderTab) #fff !important;
}
.card-header-tabs {
  margin: 0 !important;
}

/*.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {*/
/*  border-left-color: var(--arrowErrorColor) !important;*/
/*}*/
/*.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {*/
/*  border-right-color: var(--arrowErrorColor) !important;*/
/*}*/
/*.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {*/
/*  border-bottom-color: var(--arrowErrorColor) !important;*/
/*}*/
/*.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {*/
/*  border-top-color: var(--arrowErrorColor) !important;*/
/*}*/
.custom-control-input:checked~.custom-control-label::before {
  background-color: var(--backgroundButton) !important;
}
.input-group-append .btn, .input-group-prepend .btn {
  z-index: unset !important;;
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--colorTextDropdownItems) !important;
  background-color: var(--backgroundDropdownItems) !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color:var(--colorTextDropdownItems) !important;
  text-decoration: none;
  background-color: var(--hoverBackgroundDropdownItems) !important;
}
.list-group-item.active {
  color: var(--colorTextDropdownItems) !important;
  background-color: var(--backgroundDropdownItems) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fullscreenContainer{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fullscreenParent{
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
}

/*body.modal-open div#root {*/
/*  filter: blur(5px);*/
/*}*/

.collapsing.width  {
  width: 0;
  transition-property: all;
}

.collapse.width:not(.show) {
  width: 0;
}

.containerTab {
  overflow: auto;
  border: 1px solid #ced4da;
  border-radius: 0 0 .25rem .25rem;
}
.containerCol {
  padding: 1rem;
}
.containerGroup{
  max-height: 72vh;
  overflow: auto;
  border: 1px solid #dde1e5;
  border-radius: .25rem;
}
.containerField{
  max-height: 76vh;
  overflow: auto;
}
.containerTable{
  width: auto;
  overflow: auto;
  height: 57vh;
}

.cardHeader{
  font-weight: 700;
  font-size: 18px;
  color: #456f97;
}

.LinksManager {
  height: 35px;
  background: var(--white);
}

#docBody {
  margin-bottom: 35px;
}
::-webkit-scrollbar { width: 8px; height: 8px;}
::-webkit-scrollbar-button {  height: 8px; background-color: #afb1b6; }
::-webkit-scrollbar-track {  background-color: #d2def6;}
::-webkit-scrollbar-track-piece { background-color: #ffffff;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #b8c6e4; border-radius: 0.3em;}
::-webkit-scrollbar-corner { background-color: #d2d4db;}
::-webkit-resizer { background-color: #d2d4db;}

.print-area{
  display: none;
}

@media print {
  /* здесь будут стили для печати */
  body {
    visibility: hidden;
  }
  .print-area {
    position: absolute;
    top: 0;
    visibility: visible;
    display: unset;
  }
  .buttonGroup {
    visibility: hidden;
    height: 32px;
  }
  .HeaderOfTable {
    top: auto!important;
  }
  .printHeader {
    word-wrap: break-word;
    /*word-break: break-all;*/
    /*word-break: break-word;*/
  }

  /*@page {size:landscape;*/
  /*}*/
}
